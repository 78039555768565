import * as React from "react";
import Grid from "./Grid";
import Row from "./Row";
import Cell from "./Cell";
import ContentCell from "./ContentCell";
import { H3, H5, P } from "../texts";
import Button from "../Button";
import Link from "../Link";
import Image from "../Image";
import { css, withTheme } from "@delight-js/react";
import ParseMarkdown from "../ParseMarkdown";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Disclosure } from "@headlessui/react";
import Icon from "../Icon";
import YouTube from "../YouTube";
// import AlignEvenly from "../AlignEvenly";

const ContentText = withTheme(
  ({ theme, headline, headlineLevel, text, greyBackground, alignCenter }) => {
    const renderedContent = (
      <React.Fragment>
        {!!headline && (
          <H3 as={headlineLevel || "h2"}>
            {/* <AlignEvenly text={headline} /> */}
            {headline}
          </H3>
        )}
        {!!text && (
          <div css={!!headline ? css`$pt4;`(theme) : {}}>
            <ParseMarkdown markdown={text} />
          </div>
        )}
      </React.Fragment>
    );

    if (!greyBackground) {
      return <ContentCell>{renderedContent}</ContentCell>;
    }
    return (
      <Cell span={{ default: 10 / 12 }} offset={{ default: 1 / 12 }}>
        <Grid
          css={css`
            $py14;
            --color-spot1: $color-text2;
            color: $color-text2;
            background-color: $color-bg2;
          `(theme)}
          id="recipe"
        >
          <Row>
            <Cell
              span={{ default: 8 / 10, x2l: 6 / 10 }}
              offset={{ default: 1 / 10, x2l: 2 / 10 }}
              css={
                alignCenter
                  ? css`
                      text-align: center;
                    `
                  : {}
              }
            >
              {renderedContent}
            </Cell>
          </Row>
        </Grid>
      </Cell>
    );
  }
);

const ContentTagCloud = withTheme(
  ({ theme, headline, headlineLevel, cloud }) => (
    <ContentCell>
      {!!headline && <H3 as={headlineLevel || "h2"}>{headline}</H3>}
      {!!cloud && (
        <div
          css={css`
          $pt4;
          margin: -8px -5px;
          > * {
            margin: 8px 5px;
          }
        `(theme)}
        >
          {cloud.map(({ title, text, url }) => (
            <Button
              key={title}
              as={Link}
              small
              url={url}
              test={"test"}
              title={title}
            >
              {text}
            </Button>
          ))}
        </div>
      )}
    </ContentCell>
  )
);

const ContentVideo = ({ video, videoId, customCover, locale }) => {
  return (
    <Cell
      span={{ default: 10 / 12, x2l: 8 / 12 }}
      offset={{ default: 1 / 12, x2l: 2 / 12 }}
    >
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
        `}
      >
        <YouTube
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          `}
          cover={
            (customCover || video?.cover)?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          videoId={videoId || video?.videoId}
          title={video?.title}
          locale={locale}
        />
      </div>
    </Cell>
  );
};

const ContentAccordion = withTheme(
  ({ theme, headline, headlineLevel, accordion }) => {
    const { isSmallWindow } = useWindowWidth(640);
    const width = isSmallWindow ? 28 : 40;
    return (
      <ContentCell>
        {!!headline && <H3 as={headlineLevel || "h2"}>{headline}</H3>}
        {!!accordion && (
          <div css={headline ? css`$pt4;`(theme) : {}}>
            {accordion.map(({ title, description }, index) => {
              const [hover, setHover] = React.useState(false);
              const toggleHover = ({ type }) => setHover(type === "mouseover");
              return (
                <Disclosure key={title}>
                  {({ open }) => (
                    <div
                      css={
                        index
                          ? css`
                              margin-top: 28px;
                            `
                          : {}
                      }
                    >
                      <H5
                        as={Disclosure.Button}
                        css={css`
                          background: transparent;
                          border: 0;
                          display: flex;
                          cursor: pointer;
                          text-align: left;
                        `(theme)}
                        onMouseOver={toggleHover}
                        onMouseOut={toggleHover}
                      >
                        <Icon
                          type="plus"
                          width={width}
                          height={width}
                          innerProps={{ minus: open, hover, r: width / 2 }}
                          css={css`
                            margin: -0.15em 0;
                            flex-shrink: 0;

                            @media:md {
                              margin-top: -0.35em;
                            }
                          `}
                        />
                        <span
                          css={css`
                            color: $color-text;
                            flex-grow: 1;
                            margin-left: 16px;
                          `(theme)}
                        >
                          {title}
                        </span>
                      </H5>
                      <Disclosure.Panel
                        className={open ? "" : "visually-hidden"}
                        css={css`
                          $pt2;
                          margin-left: 44px;

                          @media:md {
                            margin-left: 56px;
                          }
                        `(theme)}
                        static={true}
                      >
                        <P>{description}</P>
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              );
            })}
          </div>
        )}
      </ContentCell>
    );
  }
);

const smallerSpecs = {
  span: { default: 10 / 12, x2l: 8 / 12 },
  offset: { default: 1 / 12, x2l: 2 / 12 },
};

const ContentGallery = withTheme(({ theme, gallery, ...others }) => {
  const { hasSpacing, rows } = gallery;
  return rows.map(({ images = [] }, jindex) => {
    images = images.filter((i) => !!i.localFile);
    if (images.length === 1) {
      const { localFile, alt, title } = images[0];
      return (
        <Cell {...smallerSpecs} key={jindex}>
          <Image
            image={localFile.childImageSharp.gatsbyImageData}
            alt={alt || ""}
            title={title}
            sizes="(min-width: 1280px) 62vw, 83vw"
          />
        </Cell>
      );
    } else if (images.length > 1) {
      const allRatios = images.map(({ localFile, alt, title }, index) => {
        const { gatsbyImageData } = localFile.childImageSharp;
        const { aspectRatio, width, height } = gatsbyImageData;
        return aspectRatio || (!!width && !!height) ? width / height : 1;
      });
      const ratioSum = allRatios.reduce((sum, ratio) => sum + ratio);
      const renderedImages = images.map(({ localFile, alt, title }, index) => {
        const { gatsbyImageData } = localFile.childImageSharp;
        const { aspectRatio, width, height } = gatsbyImageData;
        const ratio = aspectRatio || (!!width && !!height) ? width / height : 1;
        const quota = ratio / ratioSum;
        const sizes = `${
          hasSpacing
            ? `(min-width: 1280px) ${Math.round(0.6666 * quota * 100)}vw,`
            : ""
        }(min-width: 640px) ${Math.round(
          (hasSpacing ? 0.8333 : 1) * quota * 100
        )}vw, ${hasSpacing ? "83" : "100"}vw`;
        return (
          <Image
            key={`${title}-${index}`}
            image={gatsbyImageData}
            alt={alt || ""}
            title={title}
            style={{ flex: ratio }}
            sizes={sizes}
            css={css`
              margin: $grid-gutter 0 0 $grid-gutter;

              @media (max-width: 639.45px) {
                flex-basis: 100% !important;
              }
            `(theme)}
          />
        );
      });
      return (
        <Cell {...(hasSpacing ? smallerSpecs : {})} key={jindex}>
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              margin: calc($grid-gutter * -1) 0 0 calc($grid-gutter * -1);

              @media:md {
                flex-wrap: unset;
              }
            `}
          >
            {renderedImages}
          </div>
        </Cell>
      );
    }
    return null;
  });
});

const blocks = {
  "content-blocks.text": ContentText,
  "content-blocks.gallery": ContentGallery,
  "content-blocks.tag-cloud": ContentTagCloud,
  "content-blocks.accordion": ContentAccordion,
  "content-blocks.video": ContentVideo,
};

const ContentBox = withTheme(
  ({ theme, content, addBlocks = {}, locale, ...props }) => (
    <Grid {...props}>
      {content.map((block, index) => {
        const { strapi_component, id } = block;
        if (blocks[strapi_component] || addBlocks[strapi_component]) {
          const Comp = addBlocks[strapi_component] || blocks[strapi_component];
          return (
            <Row
              key={`${strapi_component}-${id}-${index}`}
              css={
                !index
                  ? css`$pb10`
                  : index === content.length - 1
                  ? css`$pt10`
                  : css`$py10`
              }
            >
              <Comp {...block} locale={locale} />
            </Row>
          );
        }
        return null;
      })}
    </Grid>
  )
);

export default ContentBox;
